import { Grid, TextField } from "@mui/material";
import useLabels from "../../../../hooks/use-labels";
import { formatCurrency } from "../../../../utils/common-utils";
import { FC } from "react";
import useStyles from "../../../../hooks/use-styles";
import AddIcon from "@mui/icons-material/Add";
import { Balance } from "../customTypes";

interface SecondaryCardholderHeaderProps {
  balance: Balance;
  handleAddNewCardIconClick: () => void;
}

export const SecondaryCardholderHeader: FC<SecondaryCardholderHeaderProps> = ({
  balance,
  handleAddNewCardIconClick,
}) => {
  const { availableBalance, currencyCode } = balance || {};
  const { availableBalanceLabel, searchcardholderLabel } = useLabels([
    "availableBalanceLabel",
    "searchcardholderLabel",
  ]);
  const { lightBlueColor } = useStyles(["lightBlueColor"]);

  return (
    <>
      <Grid container direction={"column"} mb={4}>
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          className="mb-2"
        >
          <Grid item className="mt-2">
            <p className="weight-500 d-flex justify-content-center flex-column">
              <span className="text-base">{availableBalanceLabel}</span>
              <span className="text-lg">
                {availableBalance && currencyCode
                  ? formatCurrency(availableBalance, currencyCode)
                  : "N/A"}
              </span>
            </p>
          </Grid>
        <Grid className="pt-3" item md={5} sm={12} xs={12}>
          <p
            className="pointer text-xs width-fit-content float-right"
            style={{ color: lightBlueColor, paddingRight: `20px` }}
            onClick={handleAddNewCardIconClick}
          >
            {"Add New"}
            <AddIcon
              style={{
                fontSize: "2rem",
                fontWeight: "100",
                marginLeft: "0.325rem",
                marginBottom: "0.2rem",
              }}
            />
          </p>
        </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SecondaryCardholderHeader;
