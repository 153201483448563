import MaterialModal from "..";
import { Cardholder } from "../../../views/dashboard/SecondaryCardholder/customTypes";

import AddNewCardModalContent from "./AddNewCardModalContent";

interface AddNewCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  cardItem: Cardholder | undefined;
}

const AddNewCardModal = ({ isOpen, ...rest }: AddNewCardModalProps) => {
  return (
    <MaterialModal open={isOpen} handleClose={rest.onClose} width={"25rem"}>
      <AddNewCardModalContent {...rest} />
    </MaterialModal>
  );
};

export default AddNewCardModal;
