import { FC, useState } from "react";

import IcActivate from "../../../../assets/ic_activate.svg";

import useStyles from "../../../../hooks/use-styles";
import { showErrorNotification } from "../../../../utils/common-utils";
import useLabels from "../../../../hooks/use-labels";
import customToast from "../../../shared/services/toaster-service";
import ConfirmationModal from "../../ConfirmationModal";
import { addNewCard } from "../../../../apis/b2c_api";
import { Cardholder } from "../../../../views/dashboard/SecondaryCardholder/customTypes";

interface AddNewCardModalProps {
  onClose: () => void;
  cardItem?: Cardholder;
}

const AddNewCardModalContent: FC<AddNewCardModalProps> = ({
  onClose,
  cardItem,
}) => {
  const [loading, setLoading] = useState(false);
  const { titleColor } = useStyles(["titleColor"]);
  const {
    confirmAddNewCardTitle,
    addNewCardconfirmationTitle,
    confirmAddNewCardLabel,
    cancelAddNewCardLabel,
    addNewCardSuccessMessage,
  } = useLabels([
    "confirmAddNewCardTitle",
    "addNewCardconfirmationTitle",
    "confirmAddNewCardLabel",
    "cancelAddNewCardLabel",
    "addNewCardSuccessMessage",
  ]);

  const onConfirmAddNewCard = (cardholderId: string) => {
    setLoading(true);
    addNewCard(cardholderId)
      .then(() => {
        setLoading(false);
        customToast.success(addNewCardSuccessMessage);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        showErrorNotification(error);
        onClose();
      });
  };

  const onConfirm = () => {
    const { cardholderId } = cardItem || {};
    cardholderId && onConfirmAddNewCard(cardholderId);
  };

  const modalProps = {
    confirmationImage: IcActivate,
    confirmationTitle: confirmAddNewCardTitle,
    confirmationText: addNewCardconfirmationTitle,
    closeLabel: cancelAddNewCardLabel,
    confirmButtonText: confirmAddNewCardLabel,
    onClose,
    titleColor,
    onConfirm,
    loading,
  };

  return <ConfirmationModal modalProps={modalProps} />;
};

export default AddNewCardModalContent;
