const defaults = {
  // Labels
  verificationTitle: "Verification",
  verificationDescription: "To identify you, we need your email",
  setNewPasswordLabel: "Set New Password",

  checkEmailTitle: "Check your email",
  checkEmailDescription: "We just sent a verification code to",

  personalInformationTitle: "Personal Information",
  personalPhoneNameDescription:
    "Please enter your first name, last name and phone number",
  personalAddressDescription: "Please provide a valid address",
  personalDobSSNDescription: "Please enter your date of birth and SSN",

  createAccountTitle: "Create Account",
  createAccountDescription:
    "This information will be used to login in the future",

  reviewTitle: "Review Details",
  agreementTitle: "Accept Agreements",
  eSignAgreementTitle: "ESign Agreement",
  agreementDescription:
    "Please read the agreement carefully before proceeding further",
  emailLabel: "Email",
  resendText: "Resend Now",
  resendLabel: "Resend Now",
  resendSubText: "Didn't receive a Code?",

  nextButtonLabel: "Next",
  previousButtonLabel: "Previous",
  loginButtonLabel: "Login",
  identityTitle: "Identity Information",

  firstNameLabel: "First Name",
  lastNameLabel: "Last Name",
  phoneNumberLabel: "Phone", //needs to be changed in db
  dobLabel: "Date of Birth",
  ssnLabel: "Social Security Number",
  confirmSsnLabel: "Confirm Social Security Number",
  addressLabel: "Address",
  address2: "Line 2",
  cityLabel: "City",
  stateLabel: "State",
  zipCodeLabel: "Zip Code",
  countryLabel: "Country",
  oldPasswordLabel: "Old Password",
  passwordLabel: "Password",
  newPasswordLabel: "New Password",
  confirmPasswordLabel: "Re-Type Password",
  reTypePasswordLabel: "Re-type New Password",
  privacyStatement: "Privacy Statement",
  agreementCheckStatement: "I agree to OpenCP",
  termsOfUse: "Terms of Use",
  consentStatement: "and consent to their",
  identityLabel: "Identity",
  personalInfoLabel: "Personal",
  chooseReasonLabel: "Choose Reason",

  availableBalanceLabel: "Available Balance",
  selectCardLabel: "Select Card",
  secureLabel: "Show Secure Data",
  hideSecureLabel: "Hide Secure Data",

  lastFourLabel: "Last Four",
  cardTypeLabel: "Card Type",
  nameOnCardLabel: "Name on Card",
  statusLabel: "Status",
  actionLabel: "Actions",
  searchcardholderLabel: "Search cardholder",

  changePinTooltip: "Change PIN",
  applyPhysicalTooltip: "Request physical card",
  appliedPhysicalTooltip: "Requested physical card",

  resendTooltip: "Resend Invitation",
  deleteTooltip: "Delete Invitation",

  directDepositTooltip: "Direct Deposit",
  orderSecondTooltip: "Order Secondary Card",
  blockCardToolTip: "Loack Card",
  activateCardToolTip: "Activate Physical Plastic",
  unblockCardToolTip: "Unlock card",

  cardModalTitle: "Card Details",
  directDepositModalTitle: "Direct Deposit",
  accountNumberLabel: "Direct Deposit Account Number",
  routingNumberLabel: "Bank Routing Number",
  closeLabel: "Close",
  cancelReissueCardLabel: "Cancel",
  saveReissueCardLabel: "Save",

  privacyPolicyLabel: "Privacy Policy",
  termsAndConditionLabel: "Terms & Conditions",
  cardholderLabel: "Cardholder Agreement",

  startDateLabel: "Start Date",
  endDateLabel: "End Date",
  recentTransactions: "Recent Transactions",
  accountStatement: "Account Statement",
  transactionDetailLabel: "Transaction Details",
  transactionAmountLabel: "Transaction Amount",
  transactionIdLabel: "Transaction Id",
  transactionDateLabel: "Transaction Date",
  transactionDescription: "Description",

  signupInvitation: "Signup Invitations",

  transactionTypeLabel: "Transaction Type",
  transactionStatusLabel: "Transaction Status",
  transactionFeeLabel: "Transaction Fee",
  merchantNameLabel: "Merchant Name",
  merchantCodeLabel: "Merchant Code",
  merchantCategoryLabel: "Merchant Category",
  merchantCountryLabel: "Merchant Country",
  merchantRegionLabel: "Merchant Region",
  merchantCityLabel: "Merchant City",

  confirmBlockTitle: "Confirm Lock",
  confirmUnblockTitle: "Confirm Unlock",
  confirmActivationTitle: "Confirm Activation",
  confirmAddNewCardTitle: "Add New Card",
  reissueCardTitle: "Reissue Card",

  confirmNameTitle: "Is this the name of the 529 account owner?",
  nameCancelLabel: 'No',
  nameSubmitLabel: 'Yes',



  enterAmountDescription: "that you want to transfer to",
  confirmTransferLabel: "Confirm Transfer",
  transactionReceiptLabel: "Transaction Receipt",
  addRecipientLabel: "Add Recipient",
  secondaryTransferLabel: "Send Funds to Secondary Card",
  recipientListLabel: "Funds Transfer",
  confirmRecipientDescription:
    "Are you sure you want to add following recipient?",

  cardStatusconfirmationTitle: "Are you sure?",
  cardActivationconfirmationTitle: "Are you sure?",
  addNewCardconfirmationTitle: "Are you sure?",
  reissueCardconfirmationTitle: "Confirm Reissue",
  reissueCardconfirmationText: "Are you sure?",

  unblockLabel: "Unlock",
  blockLabel: "Lock",
  cancelLabel: "Cancel",
  activateLabel: "Activate",
  confirmAddNewCardLabel: "Confirm",
  cancelAddNewCardLabel: "Cancel",
  cancelReissueCard: "Cancel",
  confirmReissueCard: "Confirm",

  shipmentTrackingModalTitle: "Card Shipment Tracking",
  comingSoonShipmentDescription: "Shipment tracking will soon be available!",
  ShipmentTrackingModalDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  trackingIdLabel: "Tracking ID",
  shipmentDateLabel: "Shipment Date",
  shipmentMethodLabel: "Shipment Method",

  unavailableTooltip: "Unavailable",

  applyPhysicalCardLabel: "Request Physical Card",
  verifyAddressLabel: "Verify your address",
  verifyAddressDescription: "Your card will be mailed to the address on file.",
  shippingAddressLabel: "Shipping Address",
  changePinModalTitle: "Change PIN",

  mobileLabel: "Mobile",
  relationLabel: "Relation",
  shipPhysicalCardLabel: " Ship physical card",
  feeLabel: "Fee",
  cardStateLabel: "Card State",

  secondaryCardTitle: "Secondary card details",
  secondaryCardDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer`,

  orderSecondaryCardPageTitle: "Enter Secondary Cardholder Information",
  orderSecondaryCardPageDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer`,

  editSecondaryCardTitle: "Edit Secondary Cardholder Details",  

  enterSecondaryCardholderDetailsTitle: "Enter secondary cardholder details",

  reviewCardPageTitle: "Review card details",
  reviewCardPageDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer`,

  changePasswordLabel: "Change Password",
  forgotPasswordLabel: "Forgot Password",
  registeredEmailTitle: "Provide Registered Email",
  registeredEmailDescription: `we will send you a verification code to your registered email`,
  registeredEmailLabel: "Registered Email",
  registeredEmailPlaceholder: "contact@email.com",
  continueLabel: "Continue",
  resetLabel: "Reset",

  SetPasswordTitle: "Set Password",
  setPasswordDescription: "Please set password",

  noTransactionText: "There are currently no transactions",
  noSecondaryCardholdersText: "There are currently no secondary cardholders",
  noStatementText: "There are currently no statements",
  confirmLabel: "Confirm",
  editProfileTooltip: "Edit Profile",

  transferFromLabel: "Transfer From",
  transferToLabel: "Transfer To",
  transferDateLabel: "Transfer Date",
  transferAmountLabel: "Transfer Amount",
  transferSuccessLabel: "Transfer Successful",

  enterAmountLabel: "Enter Amount",
  searchRecipientLabel: "Search Recipient",
  searchRecipientDescription: ` Enter the email address, Click search and then click add
  account to pick a member from member list to add to your list.`,

  noInvitationsText: "There are currently no Invitaitons",

  backLabel: "Back",

  payBillTooltip: "Pay Bill",
  shipmentTrackingTooltip: "Shipment Tracking",
  signupInvitationTooltip: "Signup Invitations",

  primaryLabel: "Primary",
  secondaryLabel: "Secondary",
  virtualLabel: "Virtual",
  physicalOrderedLabel: "Physical Ordered",
  physicalLabel: "Physical",
  billPaymentTitle: "Bill Payment",

  newPasswordPlaceholder: "Enter New Password",
  reTypePasswordPlaceholer: "Re-type new password",
  submitButtonText: "Submit",

  confirmDeleteLabel: "Confirm Delete",
  confirmationText: "Are you sure",
  deleteLabel: "Delete",
  signupInvitationModalDescription:
    "You can resend or delete your signup invitations from here",

  myCardTitle: "My Cards",
  secondaryCardholderTitle: "Secondary Cardholder", 
  transferFundTitle: "Transfer Funds",
  faqTitle: "FAQ",
  feeScheduleTitle: "Fee Schedule",
  privacyPolicyTitle: "Privacy Policy",
  logoutTitle: "Logout",
  profileTitle: "Profile",
  secondaryCardholderLabel: "New Secondary Cardholder",
  editSecondaryCardholderLabel: "Edit Secondary Cardholder",

  chooseAccountLabel: "Choose Account",
  wantToTransferLabel: "where you want to transfer funds",
  searchPayeeLabel: "Search Payee",
  nickNameLabel: "Nick Name",
  nickNamePlaceholder: "Enter nick name",
  enterEmailPlaceholder: "Enter email address",

  addRecipientSuccessMessage: "Recipient has been added successfully",
  deleteRecipientTipText: "Delete Recipient",
  transferFundsTipText: "Transfer Funds",
  sendButtonLabel: "Send",
  transferNowButtonLabel: "Transfer Now",
  selectAccountLabel: "Select Account",
  enterTransferAmountLabel: "Enter Transfer Amount",
  enterTransferDetailLabel: "Enter Transfer Details",

  confirmTransferDetails: `Duis aute irure dolor in reprehenderit in vlouptate velit esse cillum
  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
  proident, sunt in culpa qui officia deserunt mollit anim id est laborum`,

  makeAnotherLabel: "Make Another",
  remainingAmountLabel: "Remaining Amount",
  doneLabel: "Done",
  successfulTransferFundText: "Your funds have been successfully transferred",

  outOfWalletHeading: "Help us confirm your identity by answering a few questions.",
  outOfWalletDescription:"Kindly provide  answers to any four out of the five questions listed below. If you prefer not to answer a specific question, choose “Skip Question” from the list. Make sure to provide correct answers for four questions only.",
  outOfWalletSubtitle:"Time limit to answer these questions is 5 minutes",

  outOfWalletPopupText: "Kindly provide  answers to any four out of the five questions. If you prefer not to answer a specific question, choose “Skip Question” from the list. Make sure to provide correct answers for four questions only.",
  outOfWalletPopupButton: "Close",

  addNewCardSuccessMessage: "Success",
  reissueCardSuccessMessage: "Success",

  // styles
  titleColor: "#05315F",
  subTitleColor: "#A7A7A7",
  labelColor: "#34495E",
  placeholderColor: "#34495E",
  textColor: "#34495E",
  borderColor: "#A5A5A5",
  resendColor: "#05315F",
  timeColor: "#05315F",
  fontColor: "#0F3A5E",
  orangeColor: "#E76F6F",
  orangeButtonColor: "#FFA500",
  blackColor: "#000000",

  dashboardContentColor: "#34495E",

  reviewHeaderColor: "#F2F2F2",

  primaryButtonLabelColor: "#fff",
  primaryButtonBackground: "#0F3A5E",
  primaryButtonBorder: "#fff",

  secondaryButtonLabelColor: "#0F3A5E",
  secondaryButtonBackground: "#fff",
  secondaryButtonBorder: "#0F3A5E",

  sidebarBackground: "linear-gradient(#092338, #0F395C)",
  topBarTitleColor: "#0a2942",
  activeNavBackground: "#103A5E",
  avatarBackgroundColor: "#EFEFEF",
  avatarFontColor: "#87939D",

  activeBackground: "#2ECD72",
  inactiveBackground: "#E11022",

  horizontalLineColor: "#D7D7D7",

  boxBackgroundColor: "#EAEBED",

  previewTextColor: "#4a5465",

  listBorderColor: "#BDC3C7",

  grayColor: "#d8d8d8",
  blueColor: "#0F3A5E",
  lightBlueColor: "#047EFD",

  cardTextColor: "#000000",

  // configs

  defaultMainView: "login",
  minimumAgeLimit: 18,
  maximumAgeLimit: 30,
  resendCodeTime: 30,
  nameLength: 25,
  addressLength: 255,
  cityLength: 50,
  SignUpWithRequiredExtRefNo: "true",

  confirmTransferDescription:
    "Duis aute irure dolor in reprehenderit in vlouptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  strongPasswordOptions:
    '{"minLength":8,"minUppercase":1,"minNumbers":1,"minSymbols":1}',
  countries: '[{"code":"USA","name":"United States of America"}]',
  states:
    '[{"code":"AL","name":"Alabama"},{"code":"AK","name":"Alaska"},{"code":"AZ","name":"Arizona"},{"code":"AR","name":"Arkansas"},{"code":"CA","name":"California"},{"code":"CO","name":"Colorado"},{"code":"CT","name":"Connecticut"},{"code":"DE","name":"Delaware"},{"code":"FL","name":"Florida"},{"code":"GA","name":"Georgia"},{"code":"HI","name":"Hawaii"},{"code":"ID","name":"Idaho"},{"code":"IL","name":"Illinois"},{"code":"IN","name":"Indiana"},{"code":"IA","name":"Iowa"},{"code":"KS","name":"Kansas"},{"code":"KY","name":"Kentucky"},{"code":"LA","name":"Louisiana"},{"code":"ME","name":"Maine"},{"code":"MD","name":"Maryland"},{"code":"MA","name":"Massachusetts"},{"code":"MI","name":"Michigan"},{"code":"MN","name":"Minnesota"},{"code":"MS","name":"Mississippi"},{"code":"MO","name":"Missouri"},{"code":"MT","name":"Montana"},{"code":"NE","name":"Nebraska"},{"code":"NV","name":"Nevada"},{"code":"NH","name":"New Hampshire"},{"code":"NJ","name":"New Jersey"},{"code":"NM","name":"New Mexico"},{"code":"NY","name":"New York"},{"code":"NC","name":"North Carolina"},{"code":"ND","name":"North Dakota"},{"code":"OH","name":"Ohio"},{"code":"OK","name":"Oklahoma"},{"code":"OR","name":"Oregon"},{"code":"PA","name":"Pennsylvania"},{"code":"RI","name":"Rhode Island"},{"code":"SC","name":"South Carolina"},{"code":"SD","name":"South Dakota"},{"code":"TN","name":"Tennessee"},{"code":"TX","name":"Texas"},{"code":"UT","name":"Utah"},{"code":"VT","name":"Vermont"},{"code":"VA","name":"Virginia"},{"code":"WA","name":"Washington"},{"code":"WV","name":"West Virginia"},{"code":"WI","name":"Wisconsin"},{"code":"WY","name":"Wyoming"}]',

  eSignCheckbox: `By checking this box, I confirm that I have read and agree to the Electronic Communications ("ESIGN") Agreement, which states all disclosures and communications will be provided electronically.`,

  agreementsCheckbox: `By checking this box, I confirm that I have read and agree with the Cardholder Agreement, Fee Schedule and Privacy Policy.`,
  agreementText: `[
    {"title": "eSign", "agreement_content": "<p><b>The following disclosures are required by the federal Electronic Signatures in Global and National Commerce Act (&ldquo;E- SIGN&rdquo;). In order to open a my529 Access Card (the &ldquo;Account&rdquo;) electronically, you must acknowledge receipt of these disclosures, agree that you have read the disclosures, and provide your consent to the electronic delivery of all Communications.</b><p><br>
<p>This Electronic Communications Agreement <strong>(&ldquo;E-sign Agreement&rdquo;) </strong>applies to all Communications for those products and services offered or accessible through <a href="http://my529accesscard.com/">http://my529accesscard.com.</a> This E-sign Agreement supplements and is to be construed in accordance with the terms and conditions contained in the Account Agreement or Cardholder Agreement you received when you obtained your Account.</p><br>
<p>The Account is intended for use only by individuals who are willing and able to receive notices and communications from us exclusively through electronic means. If you do not agree to have us provide you with the legally-required notices and communications described herein in electronic and not paper form, then you may not participate in the Account program. Similarly, if after providing your consent hereunder, you withdraw such consent, we reserve the right to terminate your Account and your participation in the Account program.</p><br>
<p><strong>&ldquo;We, &ldquo;us&rdquo;, &ldquo;our&rdquo;, and &ldquo;Issuing Bank&rdquo; </strong>refer to Central Bank of Kansas City. <strong>&ldquo;You&rdquo; and &ldquo;your&rdquo; </strong>mean you, and the individual(s) identified on the Account. <strong>&ldquo;Communication&rdquo; </strong>means any customer agreements or amendments thereto, disclosures, notices, responses to claims, transaction history, privacy policies and all other information in connection with the Account or related products and services, including but not limited to information that we are required by law to provide to you in writing. <strong>&ldquo;Access Device&rdquo; </strong>means any electronic device you use to access mobile or online services to view electronic documents. This includes, but is not limited to: a traditional computer such as a desktop or laptop computer, or a mobile device such as a tablet or computer or a smartphone.</p><br>
<ol>
<li><strong>Scope of Communications to Be Provided in Electronic Form. </strong>When you use a product or service to which this E-Sign Agreement applies, you agree that we may provide you with any Communications in electronic format, to the extent allowed by law, and that we may discontinue sending paper Communications to Your consent to receive electronic Communications and transactions includes, but is not limited to:</li>
</ol>
<p>&nbsp;</p>
<ul>
<li>All legal and regulatory disclosures and Communications associated with your Account and any related products or</li>
<li>Your Account Agreement or Cardholder Agreement along with any notices about a change in the terms of your Account Agreement or Cardholder Agreement.</li>
<li>Privacy policies and</li>
<li>Error Resolution</li>
<li>Reponses to claims filed in connection with your</li>
<li>Notices regarding insufficient funds or negative balances; and</li>
<li>All other communications between us and you concerning your Account and any related transactions, products or</li>
</ul>
<p>&nbsp;</p>
<ol start="2">
<li><strong>Method of Providing Communications to You in Electronic </strong>All Communications that we provide to you in electronic form will be provided via posting to our website at <a href="http://my529accesscard.com/">http://my529accesscard.com.</a> You will be notified when a Communication or updated agreement pertaining to the Account is available. It is your responsibility to check these sources regularly for Communications and to check for any updates to the E-sign Agreement.</li>
</ol>
<p>&nbsp;</p>
<ol start="3">
<li><strong>How to Withdraw </strong>You may withdraw your consent to receive electronic Communications at any time, by contacting us at my529 Access Card, PO Box 124, Dell Rapids, SD 57022. We reserve the right to restrict or terminate your access to any and all related products if you withdraw your consent to receive electronic Communications. If your Account is closed as a result, we will mail you a refund check for any amount remaining in your Account. If you withdraw your consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.</li>
</ol>
<p>&nbsp;</p>
<ol start="4">
<li><strong>How to Update Your Records. </strong>It is your responsibility to provide us with a true, accurate and complete e-mail address, contact, and other information related to this E-Sign Agreement and your Account, and to maintain and update promptly any changes in this You can update information (such as your e-mail address) by contacting us at 1-844-545-0805. We are not responsible for any delay or failure in your receipt of the Communications if we send the Communications to the last e-mail address you have provided to us.</li>
</ol>
<p>&nbsp;</p>
<ol start="5">
<li><strong>Hardware and Software </strong>In order to access, view, and retain electronic Communications that we make available to you, you must have the following:</li>
</ol>
<p>&nbsp;</p>
<p>The format of the electronic documents may vary based on your Access Device. For example, documents are typically presented in a .pdf format on a traditional computer while documents accessed on a mobile device are typically presented</p>
<p>&nbsp;</p>
<p>natively within the application. By consenting to this Agreement, you confirm that your Access Device meets the minimum specifications and requirements necessary to view and retain your electronic documents.</p>
<p>&nbsp;</p>
<b>To best experience Online Services and electronic documents on a mobile device, you will need:</b>
<p><strong>&nbsp;</strong></p>
<ul>
<li>A mobile device with any of the following operating systems: Android or iOS (iPhone).</li>
<li>A data plan provided by your wireless</li>
<li>To access Online Services and electronic documents at <a href="http://my529accesscard.com/">http://my529accesscard.com</a> you will need a mobile browser that is compatible with and supported by your operating system (i.e., Chrome or Safari).</li>
<li>If you wish to view .pdf files on your mobile device, you will need software that accurately reads and displays .pdf files (such as the mobile version of Adobe Reader).</li>
</ul>
<p>&nbsp;</p>
<b>To best experience Online Services and electronic documents on a traditional computer, you will need:</b>
<p><strong>&nbsp;</strong></p>
<ul>
<li>A computer with any of the following operating systems: Windows 10 or higher, macOS (Apple Macintosh) or</li>
<li>An internet connection with an internet browser that is compatible with and supported by your operating system (i.e. Firefox 100 or higher, Google Chrome 100 or higher, or Safari 14 or higher).</li>
<li>Software that accurately reads and displays .pdf files (such as Adobe Reader 0 or higher).</li>
<li>A printer and/or storage device if you wish to print or retain any electronic</li>
</ul>
<p>&nbsp;</p>
<b>Changes to system requirements:</b>
<p><strong>&nbsp;</strong></p>
<p>We will notify you if our hardware or software requirements change and whether that change creates a material risk that you would not be able to access or retain your electronic documents. Continuing to use our mobile and online services after receiving notice of the change is the reaffirmation of your consent to this E-Sign Agreement.</p>
<p>&nbsp;</p>
<ol start="6">
<li><strong>Requesting Paper </strong>Generally, we will not send you a paper copy of any electronic Communication, unless requested by you. If you request a paper copy, you acknowledge that we reserve the right to charge you a reasonable fee, if applicable, for the production and mailing of paper versions of any Communication sent in electronic format. To determine, if a fee applies, please consult your Fee Schedule for all applicable fees. To request a paper copy of any electronic Communication, contact us at 1-844-545-0805. Additionally, we reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically.</li>
</ol>
<p>&nbsp;</p>
<ol start="7">
<li><strong>Communications in </strong>You accept electronic Communications provided by us as reasonable and proper notice, for the purpose of any and all laws, rules, and regulations, and agree that such electronic form fully satisfies any requirement that such Communications be provided to you &ldquo;in writing&rdquo; or in a form that you may keep. You should print or download for your records a copy of this E-Sign Agreement and any other electronic Communication that is important to you.</li>
</ol>
<p>&nbsp;</p>
<ol start="8">
<li><strong>Federal Law. </strong>You acknowledge and agree that your consent to electronic Communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act (the &ldquo;Act&rdquo;), and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means.</li>
</ol>
<p>&nbsp;</p>
<ol start="9">
<li><strong>Termination/Changes. </strong>We reserve the right, in our sole discretion, to discontinue the provision of your electronic Communications, or to terminate or change the terms and conditions on which we provide electronic We will provide you with notice of any such termination or change as required by law.</li>
</ol>
<p>&nbsp;</p>
<ol start="10">
<li>By clicking the box corresponding to the Electronic Communications Agreement on the application submission page, you adopt the check as your electronic signature and you acknowledge receipt of the E-Sign Agreement and further acknowledge that you have read the E-Sign Agreement; you affirmatively consent to the electronic receipt of any and all customer agreements or amendments thereto, disclosures, notices, responses to claims, transaction history, privacy policies and all other information in connection with the Account or related products and services, including but not limited to information that we are required by law to provide to you in writing. You further agree that your computer satisfies the hardware and software requirements specified above and that you have provided us with a current e-mail address at which we may send electronic Communications to you.</li>
</ol>
<p><strong><sup>1</sup></strong>Standard message and data rates from your wireless service provider may apply. CS230037CBKC 01/23/2023</p>"}
  ]`,
  agreementLinks:
    '[{"title":"Cardholder Agreement","link":"http://centralaccesscard.com/my529access_TC"},{"title":"Fee Schedule","link":"http://centralaccesscard.com/my529_FS"},{"title":"Privacy Policy","link":"http://centralaccesscard.com/my529_PP"}]',

  privacy_policy_link: "http://centralaccesscard.com/my529_PP",
  faqs_link: "http://centralaccesscard.com/my529_FAQ",
  tc_link: "http://centralaccesscard.com/my529access_TC",
  fee_schedule_link: "http://centralaccesscard.com/my529_FS",
  cardholder_agreement_link: "http://centralaccesscard.com/my529access_TC",
  e_sign_link: "http://centralaccesscard.com/my529access",

  agreement_error: "Scroll at bottom of the agreement to enable checkbox.",

  cardIssuanceInformation: `The my529 Access Discover® Prepaid Card is issued by Central Bank of Kansas City, Member FDIC. Discover and the Discover acceptance mark are service marks used by Central Bank of Kansas City under license from Discover Financial Services. Certain fees, terms, and conditions are associated with the approval, maintenance, and use of the Card. You should consult your Cardholder Agreement and <a href='http://centralaccesscard.com/my529_FS' target='_blank'> Fee Schedule </a> at my529accesscard.com. If you have any questions regarding the Card or such fees, terms, and conditions, you can contact us 24/7/365 toll-free at 1-844-545-0805.`,

  patriotActNoticeBold: `IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW CARD ACCOUNT: `,
  patriotActNotice: `To help the government fight the funding of terrorism and money
  laundering activities, federal law requires all financial institutions
  to obtain, verify, and record information that identifies each person
  who opens a Card Account. What this means for you: When you open a Card
  Account, we will ask for your name, address, date of birth, and other
  information that will allow us to identify you. We may also ask to see a
  copy of your driver's license or other identifying documents.`,

  directDepositModalInfo: `You will be able to get DDA and routing number against the card. Using
  this DDA, you can share information to your employer to get a pay
  check and fund the card.`,

  dateRangeFormat: "YYYY-MM-DD",

  copyrightText: "2022 © Copyright Open CP. All Rights Reserved",
  signupReasonType: "SU",
  resetPasswordReasonType: "RP",
  resetPasswordSuccessMessage: "Password has been reset successfully",
  updateProfileSuccessMessage: "Profile has been updated successfully",
  deleteInvitationSuccessMessage: "Invitation has been deleted successfully",
  resendInvitationSuccessMessage: "Invitation has been resent successfully",

  cardReceiveService:
    "Contact Customer Service toll-free at 1-844-545-0805 if you don't receive your card in 7-10 business days.",
  cardActivationNotice:
    "Note: Please activate your physical card as soon as you receive it.",

  relations:
    '[{"value":"Child","name":"Child"},{"value":"Grandchild","name":"Grandchild"},{"value":"Niece","name":"Niece"},{"value":"Nephew","name":"Nephew"},{"value":"Self","name":"Self"},{"value":"Spouse","name":"Spouse"},{"value":"Sibling","name":"Sibling"},{"value":"Cousin","name":"Cousin"},{"value":"Other","name":"Other"}]',

  blockSuccessMessage: "Card has been locked successfully",
  activateSuccessMessage: "Card has been activated successfully",
  transferFundsSuccessMessage: "Funds have been transferred successfully",
  unblockSuccessMessage: "Card has been unlocked successfully",

  secondaryCardSuccessMessage:
    "An invitation link has been sent to the provided email",
  secondaryCardEmailExistMessage:
    "This email is already been used, please provide different email!",
  secondaryCardFee: 0,

  applyPhysicalCardSuccessMessage:
    "Physical card has been requested successfully",
  cardActivationSuccessMessage: "Card has been activated successfully",
  cardholderLinkSuccessMessage:
    "Secondary cardholder has been linked successfully, Please login ",

  showInvitationMenu: false,

  //virtual card view
  cardRemitLogo: "https://cb-widget-assets.s3.amazonaws.com/my529Logo.png",
  cardBackground: "https://cb-widget-assets.s3.amazonaws.com/my529bg.jpg",

  //Needs to be added in errors
  AU015: "Invalid Request: Provided Email has already been used.",
  AU022: "Invalid OTP: Retry Limit Exceeded",
  AU011: "Invalid OTP",
  Min_SSN_Length: "Minimum 9 digits!",

  GENERAL: "Something went wrong",
  REQUIRED: "Required!",
  INVALID_EMAIL: "Invalid Email!",
  EMPTY_EMAIL: "Please enter email",
  INVALID_PHONE: "Invalid Phone Number!",
  WEAK_PASSWORD:
    "Must contain uppercase, lowercase, digit and special character!",
  MISMATCH_PASSWORD: "Password do not match!",
  SHORT_PASSWORD: "Minimum 8 characters!",
  INVALID_SSN: "Invalid SSN!",
  INVALID_CONFIRM_SSN: "Invalid Confirm SSN",
  INVALID_CITY: "Invalid City!",
  INVALID_POSTAL: "Invalid zip code!", //needs to be changed in db
  SHORT_POSTAL: "Minimum length should be 5",
  EMAIL_ALREADY_EXISTS: "Email already exists!",
  STREET_ADDRESS: "We require a physical address. Cards cannot be sent to P.O. Boxes, APOs, or FPOs.",

  INVALID_INVITATION_EMAIL: "Could not find any invitation for this email!",
  USED_SUPPLEMENTARY_EMAIL: "Invitation already used!",

  CARD_NOT_DELIVERED: "Physical card has not been dispatched yet!",
  MAX_AGE_LIMIT: "Maximum age limit is 30 years old",
  MIN_AGE_LIMIT: "Minimum age limit is 18 years old",
  SEC_MIN_AGE_LIMIT: "Minimum age limit is 17 years old",
  MAX_AMOUNT_LIMIT: "Amount can not be greater than available balance",
  MIN_AMOUNT_LIMIT: "Amount must be greater than 0",
  INVALID_NUMBER: "Invalid Number",
  INVALID_DECIMAL: "Invalid decimal points",
  SSN_MISMATCH: "Confirm SSN mismatch",

  tabsRefreshTime_onBuild: "60000",
  screenRefreshTime_onBuild: "300000",


} as any;

export default defaults;
