import moment from "moment";
import {
  CREATE_CARD_HOLDER,
  SUBMIT_OOWANSWERS,
  UPDATE_CARD_HOLDER,
} from "../../graphql/graphql";
import { TransactionHistoryRequest } from "../../interfaces/transaction";
import { query, mutation } from "../../utils/apollo";
const { v4: uuidv4 } = require("uuid");

export const fetchMetaData = () => {
  return query(`
    query metadata {
      metadata
    }
  `);
};

export const createCardHolder = (cardholder: any) => {
  const {
    email,
    password,
    dob: dateOfBirth,
    confirmationToken,
    firstName: first_name,
    lastName: last_name,
    address,
    address2,
    gender,
    addressType,
    postalCode,
    countryDialingCode,
    phoneNumber,
    phoneType,
    country,
    ssn,
    region,
    city,
    identificationType,
    extRefNo,
  } = cardholder;

  return mutation(CREATE_CARD_HOLDER, {
    email,
    emailConfirmationToken: confirmationToken,
    password,
    addresses: [
      {
        address1: address,
        address2,
        addressType,
        postalCode,
        region,
        country,
        city,
      },
    ],
    gender,
    citizenshipCountryCode: country,
    dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
    firstName: first_name,
    lastName: last_name,
    jwtToken: extRefNo,

    identificationDocs: [
      {
        identificationType,
        identificationValue: ssn,
        identificationIssuanceCountry: country,
      },
    ],
    phoneDetails: [
      {
        countryDialingCode,
        phoneNumber,
        phoneType,
      },
    ],
  });
};

export const updateCardHolder = (cardholder: any, cardholderId: string) => {
  const {
    dob: dateOfBirth,
    firstName: first_name,
    lastName: last_name,
    address,
    addressType,
    postalCode,
    countryDialingCode,
    phoneNumber,
    phoneType,
    country,
    region,
    city,
    email,
    ssn,
  } = cardholder;

  return mutation(UPDATE_CARD_HOLDER, {
    cardholderId,
    addresses: [
      {
        address1: address,
        addressType,
        postalCode,
        region,
        country,
        city,
      },
    ],
    citizenshipCountryCode: country,
    dateOfBirth,
    firstName: first_name,
    lastName: last_name,
    ssn,
    email,
    phoneDetails: [
      {
        countryDialingCode,
        phoneNumber,
        phoneType,
      },
    ],
  });
};

export const submitOowAnswers = (formData: any) => {
  const {
    cardholderId,
    oowAnswers,
    email,
    firstName,
    lastName,
    emailConfirmationToken,
    password,
  } = formData;

  return mutation(SUBMIT_OOWANSWERS, {
    cardholderId,
    oowAnswers,
    email,
    firstName,
    lastName,
    emailConfirmationToken,
    password,
  });
};
export const fetchTransfer = (transferProps: any) => {
  const { cardId, dateFrom, dateTo, limit, offset, beneficiaryCardLastFour } =
    transferProps;

  return query(
    `query transferHistory ($beneficiaryCardLastFour: String, $beneficiaryName: String, $cardId: ID!, $dateFrom: String, $dateTo: String, $limit: Int, $offset: Int) {
      transferHistory (beneficiaryCardLastFour: $beneficiaryCardLastFour, beneficiaryName: $beneficiaryName, cardId: $cardId, dateFrom: $dateFrom, dateTo: $dateTo, limit: $limit, offset: $offset) {
          edges {
              cursor
              node {
                  amount
                  beneficiary {
                      beneficiaryId
                      beneficiaryType
                  }
                  beneficiaryCardLastFour
                  beneficiaryName
                  beneficiaryTransactionId
                  currencyCode
                  extTransRefNo
                  source {
                      sourceId
                      sourceType
                  }
                  sourceTransactionId
                  state
                  transferDatetime
                  transferId
                  transferType
              }
          }
          nodes {
              amount
              beneficiary {
                  beneficiaryId
                  beneficiaryType
              }
              beneficiaryCardLastFour
              beneficiaryName
              beneficiaryTransactionId
              currencyCode
              extTransRefNo
              source {
                  sourceId
                  sourceType
              }
              sourceTransactionId
              state
              transferDatetime
              transferId
              transferType
          }
          pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              limit
              offset
              startCursor
              totalPages
              totalRecords
          }
      }
  }`,
    {
      cardId,
      dateFrom,
      dateTo,
      limit,
      offset,
      beneficiaryCardLastFour,
    }
  );
};

export const fetchAllRecipients = (recipientType: string) => {
  return query(
    `query recipients ($recipientType: String!) {
    recipients (recipientType: $recipientType) {
        email
        firstName
        lastName
        middleName
        mobileNo
        nickName
        photoUrl
        recipientId
        recipientType
    }
}`,
    {
      recipientType,
    }
  );
};

export const getCardholder = () => {
  return query(`query cardholder {
    cardholder {
        addresses {
            address1
            address2
            addressType
            city
            country
            postalCode
            region
        }
        cardholderId
        cards {
            primaryCardId
            shipmentInfo{
              shipmentDate
              shipmentMethodInfo
              trackingNumber
              designId
            }
            additionalData {
                accountNumber
                activationDate
                aka
                cardExpiration
                designId
                issueDate
                key
                profession
                routingNumber
                cardholderId
            }
            balance {
                availableBalance
                cardId
                currencyCode
                ledgerBalance
            }
            cardId
            physicalCardStatus
            cardholder {
              cardholderId
              citizenshipCountryCode
              dateOfBirth
              email
              externalRefNo
              firstName
              gender
              kycReasonCodes
              kycStatus
              lastName
              middleName
              primaryCardholderId
              phoneDetails {
                countryDialingCode
                phoneNumber
                phoneType
            }
              identificationDocs {
                identificationDocumentUrl
                identificationExpiryDate
                identificationIssuanceCountry
                identificationIssuanceRegion
                identificationType
                identificationValue
            }
              addresses {
                address1
                address2
                addressType
                city
                country
                postalCode
                region
              }
          }
          
            cardholderId
            currencyCode
            externalRefNo
            lastFour
            nameOnCard
            programId
            status 
        }
        citizenshipCountryCode
        dateOfBirth
        email
        externalRefNo
        firstName
        gender
        identificationDocs {
            identificationDocumentUrl
            identificationExpiryDate
            identificationIssuanceCountry
            identificationIssuanceRegion
            identificationType
            identificationValue
        }
        kycReasonCodes
        kycStatus
        lastName
        middleName
        primaryCardholderId
        phoneDetails {
            countryDialingCode
            phoneNumber
            phoneType
        }
    }
}`);
};

export const getVirtualCard = (cardId: string) => {
  return query(
    `query authInfo ($cardId: String!) {
    authInfo (cardId: $cardId) {
        cardId
        secureUrl
        expiryDate
    }
}`,
    {
      cardId,
    }
  );
};

export const getTransactionHistory = (
  transactionProps: TransactionHistoryRequest
) => {
  const { cardId, dateFrom, dateTo, limit, offset } = transactionProps;
  return query(
    `query transactions ($dateFrom: String, $dateTo: String, $limit: Int, $offset: Int, $cardId: ID!) {
        transactions (cardId: $cardId, dateFrom: $dateFrom, dateTo: $dateTo, limit: $limit, offset: $offset) {
            nodes {
                amount
                cardId
                cardBin
                cardLastFour
                cardholderId
                deviceType
                network
                currencyCode
                extTransRefNo
                international
                isoMessageType
                merchant {
                    mcc
                    mccGroup
                    mccName
                    merchantCity
                    merchantCountry
                    merchantName
                    merchantRegion
                }
                runningBalance
                state
                transactionDatetime
                transactionId
                transactionType
                description
                additionalData {
                    memo
                    feeAmount
                    transactionDetails
                    originalTransactionId
                    transactionImpact
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                limit
                offset
                startCursor
                totalPages
                totalRecords
            }
        }
    }`,
    {
      cardId,
      dateFrom,
      dateTo,
      limit,
      offset,
    }
  );
};
// export const updateInvitation =(
//   citizenshipCountryCode: string,
//   dateOfBirth: string,
//   firstName: string,
//   lastName: string,
//   token: string,
//   identificationDocs: [
//     {
//       identificationType,
//       identificationValue: ssn,
//       identificationIssuanceCountry: country
//     }
//   ],

// )

export const changeCardStatus = (
  cardId: string,
  status: string,
  reason: string
) => {
  return mutation(
    `mutation changeStatus ($cardId: ID!, $reason: String!, $status: String!) {
    changeStatus (cardId: $cardId, reason: $reason, status: $status) {
        card {
            primaryCardId
            shipmentInfo{
              shipmentDate
              shipmentMethodInfo
              trackingNumber
              designId
            }
            additionalData {
                accountNumber
                activationDate
                aka
                cardExpiration
                designId
                issueDate
                key
                profession
                routingNumber
                cardholderId
            }
            cardholder {
              cardholderId
              citizenshipCountryCode
              dateOfBirth
              email
              externalRefNo
              firstName
              gender
              kycReasonCodes
              kycStatus
              lastName
              middleName
              primaryCardholderId
              phoneDetails {
                countryDialingCode
                phoneNumber
                phoneType
            }
              identificationDocs {
                identificationDocumentUrl
                identificationExpiryDate
                identificationIssuanceCountry
                identificationIssuanceRegion
                identificationType
                identificationValue
            }
              addresses {
                address1
                address2
                addressType
                city
                country
                postalCode
                region
              }
          }
            balance {
                availableBalance
                cardId
                currencyCode
                ledgerBalance
            }
            cardId
            physicalCardStatus
            cardholderId
            currencyCode
            externalRefNo
            lastFour
            nameOnCard
            programId
            status
        }
    }
}`,
    {
      cardId,
      status,
      reason,
    }
  );
};

export const fetchAccountStatements = (cardId: string) => {
  return query(
    `query statements ($cardId: String!) {
    statements (cardId: $cardId) {
        description
        dispatchStatus
        endDate
        id
        requestedOn
        startDate
    }
}`,
    { cardId }
  );
};

export const signupInvitations = () => {
  return query(
    `query signupInvitations  {
      signupInvitations {
        email
        id
        profile
        status
        token
        expiryTime
        
    }
}`
  );
};

export const downloadStatement = (id: string) => {
  return query(
    `query statementLink ($id: String!) {
    statementLink (id: $id)
}`,
    { id }
  );
};

export const getChangePinWidget = (cardId: string) => {
  return query(
    `query pinChangeUrl ($cardId: String!) {
    pinChangeUrl (cardId: $cardId) {
        secureUrl
        expiry
    }
}`,
    { cardId }
  );
};

export const getBillPaymentUrl = (cardId: string) => {
  return query(
    `query billPaymentUrl ($cardId: String!) {
      billPaymentUrl (cardId: $cardId) {
        url
    }
}`,
    { cardId }
  );
};

export const orderSecondaryCard = (cardProps: any) => {
  const {
    countryDialingCode,
    phoneNumber,
    phoneType,
    ssn,
    country,
    identificationType,
    ...restCard
  } = cardProps;

  const lowerEmail = restCard.email.toLowerCase();
  return mutation(
    `mutation createSupplementaryCardholder ($cardId: ID!, $dateOfBirth: String!, $email: String, $firstName: String!, $lastName: String!, $phoneDetails: [PhoneDetailInput!],$citizenshipCountryCode: String!, $identificationDocs: [IdentificationDocInput!]!) {
    createSupplementaryCardholder (cardId: $cardId, dateOfBirth: $dateOfBirth, email: $email, firstName: $firstName, lastName: $lastName, phoneDetails: $phoneDetails, citizenshipCountryCode: $citizenshipCountryCode,  identificationDocs: $identificationDocs) {
      invitation{email
  id
  status
  token
      }
    }
}`,
    {
      ...restCard,
      email: lowerEmail,
      phoneDetails: [
        {
          countryDialingCode,
          phoneNumber,
          phoneType,
        },
      ],
      citizenshipCountryCode: country,
      identificationDocs: [
        {
          identificationType,
          identificationValue: ssn,
          identificationIssuanceCountry: country,
        },
      ],
    }
  );
};
export const signupWithExtRefNo = (jwtToken: string) => {
  return mutation(
    `mutation signupWithExtRefNo ($jwtToken: String!) {
      signupWithExtRefNo (jwtToken: $jwtToken) {
          success
      }
  }`,
    {
      jwtToken,
    }
  );
};

export const getLegalAgreement = () => {
  return query(
    `query legalAgreements {
    legalAgreements {
        description
        id
        referenceLink
        revisionInfo
        title
    }
}`
  );
};

export const completeSupplementarySignup = (data: any) => {
  const { token, addresses, password, emailConfirmationToken } = data;
  return mutation(
    `mutation completeSupplementarySignup ($addresses: [AddressInput!]!, $token: String!, $password: String!,$emailConfirmationToken: String!) {
      completeSupplementarySignup (addresses: $addresses, token: $token, password: $password, emailConfirmationToken: $emailConfirmationToken) {
          success
      }
  }`,
    {
      addresses,
      token,
      password,
      emailConfirmationToken,
    }
  );
};

export const getSignupInvitation = (email: string) => {
  email = email.toLocaleLowerCase();
  return query(
    `query getActiveSignupInvitation ($email: String!) {
    getActiveSignupInvitation (email: $email) {
        email
        id
        profile
        status
        token
    }
}`,
    { email }
  );
};

export const resendInvitation = (token: string) => {
  return mutation(
    `mutation resendInvitation ($token: ID!) {
    resendInvitation (token: $token) {
      success
    }
  }`,
    {
      token,
    }
  );
};
export const deleteInvitation = (token: string) => {
  return mutation(
    `mutation deleteInvitation ($token: ID!) {
    deleteInvitation (token: $token) {
        success
      }
    }`,
    {
      token,
    }
  );
};
export const applyForPhysicalCard = (cardId: string) => {
  return mutation(
    `mutation orderPhysicalCard ($cardId: ID!) {
    orderPhysicalCard (cardId: $cardId) {
        card {
          primaryCardId
          shipmentInfo{
            shipmentDate
            shipmentMethodInfo
            trackingNumber
            designId
          }
            additionalData {
                accountNumber
                activationDate
                aka
                cardExpiration
                cardholderId
                designId
                issueDate
                key
                processorToken
                profession
                routingNumber
                cardholderId
            }
            cardholder {
              cardholderId
              citizenshipCountryCode
              dateOfBirth
              email
              externalRefNo
              firstName
              gender
              kycReasonCodes
              kycStatus
              lastName
              middleName
              primaryCardholderId
              phoneDetails {
                countryDialingCode
                phoneNumber
                phoneType
            }
              identificationDocs {
                identificationDocumentUrl
                identificationExpiryDate
                identificationIssuanceCountry
                identificationIssuanceRegion
                identificationType
                identificationValue
            }
              addresses {
                address1
                address2
                addressType
                city
                country
                postalCode
                region
              }
          }
            balance {
                availableBalance
                cardId
                currencyCode
                ledgerBalance
            }
            cardId
            physicalCardStatus
            cardholderId
            currencyCode
            externalRefNo
            lastFour
            nameOnCard
            physicalCardStatus
            programId
            programType
            
            status
        }
    }
}`,
    { cardId }
  );
};

export const activatePhysicalCard = (cardId: string) => {
  return mutation(
    `mutation activatePhysicalCard ($cardId: ID!) {
      activatePhysicalCard (cardId: $cardId) {
      card {
        primaryCardId
        shipmentInfo{
          shipmentDate
          shipmentMethodInfo
          trackingNumber
          designId
        }
          additionalData {
              accountNumber
              activationDate
              aka
              cardExpiration
              cardholderId
              designId
              issueDate
              key
              processorToken
              profession
              routingNumber
              cardholderId
          }
          cardholder {
            cardholderId
            citizenshipCountryCode
            dateOfBirth
            email
            externalRefNo
            firstName
            gender
            kycReasonCodes
            kycStatus
            lastName
            middleName
            primaryCardholderId
            phoneDetails {
              countryDialingCode
              phoneNumber
              phoneType
          }
            identificationDocs {
              identificationDocumentUrl
              identificationExpiryDate
              identificationIssuanceCountry
              identificationIssuanceRegion
              identificationType
              identificationValue
          }
            addresses {
              address1
              address2
              addressType
              city
              country
              postalCode
              region
            }
        }
          balance {
              availableBalance
              cardId
              currencyCode
              ledgerBalance
          }
          cardId
          physicalCardStatus
          cardholderId
          currencyCode
          externalRefNo
          lastFour
          nameOnCard
          physicalCardStatus
          programId
          programType          
          status
      }
    }
}`,
    { cardId }
  );
};

export const getShipmentInfo = (cardId: string) => {
  return query(
    `query card ($cardId: ID!) {
    card (cardId: $cardId) {
      shipmentInfo{
        shipmentDate
        shipmentMethodInfo
        trackingNumber
        designId
      }
      cardId
    }
}`,
    {
      cardId,
    }
  );
};

export const searchRecipient = (email: string) => {
  return query(
    `query searchRecipients ($limit: Int, $offset: Int, $emailOrPhone: String!) {
    searchRecipients (emailOrPhone: $emailOrPhone, limit: $limit, offset: $offset) {
        nodes {
            cardholderId
            email
            firstName            
            lastName
            middleName
            citizenshipCountryCode
            phoneDetails {
                countryDialingCode
                phoneNumber
                phoneType
            }
        }
        pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            limit
            offset
            startCursor
            totalPages
            totalRecords
        }
    }
}`,
    { emailOrPhone: email, offset: 1, limit: 1 }
  );
};

export const addRecipient = (recipientProps: any) => {
  return mutation(
    `mutation addRecipient ($cardholderId: ID, $country: String, $email: String, $firstName: String, $lastName: String, $nickName: String!, $recipientType: String!) {
      addRecipient (cardholderId: $cardholderId, email: $email, country: $country, firstName: $firstName, lastName: $lastName, nickName: $nickName, recipientType: $recipientType) {
          cardholderRecipient {
              cardLastfour
              email
              firstName
              lastName
              middleName
              mobileNo
              nickName
              photoUrl
              recipientId
              recipientType
              uiUri
          }
      }
}`,
    { ...recipientProps }
  );
};

export const getAllRecipients = () => {
  return query(
    `query recipients ($recipientType: String!) {
      recipients (recipientType: $recipientType) {
          email
          firstName
          lastName
          middleName
          mobileNo
          nickName
          photoUrl
          recipientId
          recipientType
      }
  }`
  );
};

export const fundTransfer = (transferProps: any) => {
  const {
    amount,
    recipientId,
    currency,
    fromCardId,
    SecondaryTransfer,
    beneficiary,
  } = transferProps;

  return mutation(
    `mutation fundTransfer ($additionalData: JSON, $toCardId: String,$amount: Float!, $beneficiary: FundTransferBeneficiaryInput, $currency: String!, $extTransRefNo: String, $fromCardId: ID, $source: SourceInput) {
    fundTransfer (additionalData: $additionalData, toCardId: $toCardId,amount: $amount, beneficiary: $beneficiary, currency: $currency, extTransRefNo: $extTransRefNo, fromCardId: $fromCardId, source: $source) {
        transaction {
            transactionId
            transactionType
        }
    }
}`,
    {
      amount,
      currency,
      additionalData: {},
      fromCardId: "",
      source: {
        sourceId: fromCardId,
        sourceType: "CARD",
      },

      toCardId: "",
      beneficiary: {
        beneficiaryId: SecondaryTransfer ? beneficiary.cardId : recipientId,
        beneficiaryType: SecondaryTransfer ? "CARD" : "RECIPIENT",
      },

      extTransRefNo: uuidv4(),
    }
  );
};

export const getlinkedProfiles = () => {
  return query(
    `query linkedProfiles  {
    linkedProfiles {
        addresses {
            address1
            address2
            addressType
            city
            country
            postalCode
            region
        }
        cardholderId
        cards {
            additionalData {
                accountNumber
                activationDate
                aka
                cardExpiration
                cardholderId
                designId
                issueDate
                key
                processorToken
                profession
                routingNumber
                expiryDate
            }
            cardId
            cardholderId
            currencyCode
            externalRefNo
            lastFour
            nameOnCard
            network
            physicalCardStatus
            primaryCardId
            programId
            programType
            status
        }
        identificationDocs {
                identificationDocumentUrl
                identificationExpiryDate
                identificationIssuanceCountry
                identificationIssuanceRegion
                identificationType
                identificationValue
        }
        citizenshipCountryCode
        dateOfBirth
        email
        externalRefNo
        firstName
        gender
        kycReasonCodes
        kycStatus
        lastName
        middleName
        status
        phoneDetails {
            countryDialingCode
            phoneNumber
            phoneType
        }
    }
}`
  );
};

export const addNewCard = (cardholderId: string) => {
  return mutation(
    `mutation addCard ($cardholderId: String!) {
    addCard (cardholderId: $cardholderId) {
        card {
            additionalData {
                accountNumber
                activationDate
                aka
                cardExpiration
                cardholderId
                designId
                issueDate
                key
                processorToken
                profession
                routingNumber
            }
            availableBalance
            cardId
            currencyCode
            externalRefNo
            lastFour
            nameOnCard
            physicalCardStatus
            programId
            programType
            status
            network
        }
    }
}`,
    { cardholderId }
  );
};

export const reIssueCard = (cardId: string,  issueType: string) => {
  return mutation(
    `mutation reIssueCard ($cardId: String!, $issueType: String!) {
    reIssueCard (cardId: $cardId, issueType: $issueType) {
        card {
            additionalData {
                accountNumber
                activationDate
                aka
                cardExpiration
                cardholderId
                designId
                issueDate
                key
                processorToken
                profession
                routingNumber
            }
            availableBalance
            cardId
            currencyCode
            externalRefNo
            lastFour
            nameOnCard
            physicalCardStatus
            programId
            programType
            status
            network
        }
    }
}`,
    { cardId, issueType }
  );
};
