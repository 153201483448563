import { FC, useState } from "react";

import IcActivate from "../../../../assets/ic_activate.svg";

import useStyles from "../../../../hooks/use-styles";
import { showErrorNotification } from "../../../../utils/common-utils";
import useLabels from "../../../../hooks/use-labels";
import customToast from "../../../shared/services/toaster-service";
import ConfirmationModal from "../../ConfirmationModal";
import { reIssueCard } from "../../../../apis/b2c_api";
import Loader from "../../../shared/loader/loader";
import FlexButtons from "../../../flex-button";
import CustomButton from "../../../custom-button";
import { Grid } from "@mui/material";
import FormField from "../../../form/form-field";
import { reissueCardModalFields } from "../../../../utils/fields.utils";
import { errorMessage } from "../../../../utils/validation";

interface ReissueCardModalProps {
  onClose: () => void;
  cardId?: string;
  reason?: string;
}

const ReissueCardModalContent: FC<ReissueCardModalProps> = ({
  onClose,
  cardId,
  reason,
}) => {
  const {
    reissueCardTitle,
    reissueCardSuccessMessage,
    reissueCardconfirmationTitle,
    reissueCardconfirmationText,
    saveReissueCardLabel,
    cancelReissueCardLabel,
    chooseReasonLabel,
    confirmReissueCard,
    cancelReissueCard,
  } = useLabels([
    "reissueCardTitle",
    "reissueCardSuccessMessage",
    "reissueCardconfirmationTitle",
    "reissueCardconfirmationText",
    "saveReissueCardLabel",
    "cancelReissueCardLabel",
    "chooseReasonLabel",
    "confirmReissueCard",
    "cancelReissueCard",
  ]);

  const reasons = [
    { value: "RE-ISSUE", name: "Re-Issue" },
    { value: "EXPIRED", name: "Expired" },
    { value: "DAMAGED", name: "Damaged" },
    { value: "LOST", name: "Lost" },
    { value: "STOLEN", name: "Stolen" },
    { value: "REPLACE", name: "Replace" },
  ];

  const [loading, setLoading] = useState(false);
  const { titleColor } = useStyles(["titleColor"]);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const [formData, setFormData] = useState({
    chooseReason: reason || "",
  });

  const validate = (key: string, value: string, options?: any) => {
    let errors = { ...fieldErrors };

    if (typeof value === "string" && !value.trim() && options.required) {
      errors[key] = errorMessage.required;
    } else {
      delete errors[key];
    }
    setFieldErrors(errors);
  };

  const onFieldChange = (
    key: string,
    value: string,
    options?: { required?: boolean }
  ) => {
    validate(key, value, options);
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validFields = () => {
    const errors: any = {};
    const requiredFields = ["chooseReason"];
    let valid = true;

    requiredFields.forEach((field) => {
      const value = formData[field as keyof typeof formData];
      if (!value) {
        errors[field] = errorMessage.required;
        valid = false;
      }
    });

    setFieldErrors(errors);
    return valid;
  };

  const onSaveReason = () => {
    if (validFields()) {
      setDisplayConfirmationModal(true);
    }
  };

  const onConfirm = () => {
    if (!formData.chooseReason) {
      setFieldErrors({ chooseReason: errorMessage.required });
      return;
    }

    setLoading(true);
    cardId &&
      reIssueCard(cardId, formData.chooseReason)
        .then(() => {
          setLoading(false);
          customToast.success(reissueCardSuccessMessage);
          onClose();
        })
        .catch((error) => {
          setLoading(false);
          showErrorNotification(error);
          onClose();
        });
  };

  const modalProps = {
    confirmationImage: IcActivate,
    confirmationTitle: reissueCardconfirmationTitle,
    confirmationText: reissueCardconfirmationText,
    closeLabel: cancelReissueCard,
    confirmButtonText: confirmReissueCard,
    onClose,
    titleColor,
    onConfirm,
    loading,
  };

  const fieldProps = {
    chooseReasonLabel,
    reasons,
  };

  return displayConfirmationModal ? (
    <ConfirmationModal modalProps={modalProps} />
  ) : (
    <>
      <div className="mat-modal-body text-center pb-4" style={{ color: titleColor }}>
        <p className="weight-600 title">{reissueCardTitle}</p>

        <Grid className="my-5" container columnSpacing={1}>
          {reissueCardModalFields(fieldProps).map((fieldItem, index) => {
            const { name, required } = fieldItem.elementProps || {};
            return (
              <Grid item md={12} xs={12} key={index}>
                <FormField
                  {...fieldItem}
                  value={formData[name as keyof typeof formData]}
                  error={fieldErrors[name]}
                  onChange={(name: string, value: string) =>
                    onFieldChange(name, value, { required })
                  }
                  {...fieldProps}
                />
              </Grid>
            );
          })}
        </Grid>

        {loading && <Loader className="custom-loader mb-3" />}

        <FlexButtons className="layout-buttons">
          <CustomButton
            className="transparent-background weight-500"
            type="outline"
            text={cancelReissueCardLabel}
            onClick={onClose}
            disabled={loading}
          />
          <CustomButton
            type="primary"
            text={saveReissueCardLabel}
            onClick={onSaveReason}
            disabled={loading}
          />
        </FlexButtons>
      </div>
    </>
  );
};

export default ReissueCardModalContent;
