import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useStyles from "../../hooks/use-styles";
import CustomSecondaryCardsList from "../customSecondaryCardsList";
import { FC, Fragment } from "react";
import {
  Cardholder,
  CardholderRow,
  CardsRow,
} from "../../views/dashboard/SecondaryCardholder/customTypes";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#585858",
    padding: "7px",
    BsBorderBottom: "2px solid black",
    fontSize: ".8rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: ".8rem",
    color: "#34495E",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "th:first-of-type, td:first-of-type": {
    paddingLeft: 50,
  },
  "td:first-of-type": {
    color: "#047EFD",
  },
  td: {
    padding: "16px 7px",
  },
}));

interface CustomListProps {
  customStyledTableRow?: typeof TableRow;
  customStyledTableCell?: typeof TableCell;
  columns: Array<string>;
  rows: CardholderRow[];
  cardsColumns?: Array<string>;
  secondaryCards?: CardsRow[];
  noDataText?: string;
  scrollableHeight?: number;
  noDataStyle?: any;
  onRowClick: (cardholder: Cardholder) => void;
}

const CustomList: FC<CustomListProps> = ({
  customStyledTableRow = StyledTableRow,
  customStyledTableCell = StyledTableCell,
  columns,
  rows,
  cardsColumns = [],
  secondaryCards = [],
  noDataText,
  scrollableHeight,
  noDataStyle,
  onRowClick,
}) => {
  const { fontColor } = useStyles(["fontColor"]);
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "0",
        boxShadow: "unset",
        backgroundColor: "transparent",
        height: scrollableHeight || "100%",
      }}
    >
      <Table
        stickyHeader
        sx={{ minWidth: 700, border: 0 }}
        aria-label="customized table"
      >
        <TableHead>
          <StyledTableRow>
            {columns.map((column: string) => (
              <StyledTableCell key={column} align="left" className="weight-600">
                {column}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row: CardholderRow, rowIndex: number) => (
              <Fragment>
                <StyledTableRow key={`row ${rowIndex}`}>
                  {Object.keys(row).map((key: string, colIndex: number) => (
                    <StyledTableCell
                      key={`${key}-${colIndex}-${rowIndex}`}
                      align="left"
                    >
                      {colIndex === 0 ? (
                        <span>{row[key as keyof CardholderRow]}</span>
                      ) : (
                        row[key as keyof CardholderRow]
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
                {row.isExpanded && (
                  <TableRow key={`expanded-${rowIndex}`}>
                    <TableCell
                      colSpan={columns.length}
                      sx={{ padding: 0, border: "none" }}
                    >
                      <CustomSecondaryCardsList
                        columns={cardsColumns}
                        rows={secondaryCards}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <p
                  className="table-no-data-text subTitle"
                  style={{ color: fontColor, ...noDataStyle }}
                >
                  {noDataText}
                </p>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomList;
