import {
    formatExpiryDate,
    formatPhoneNumber, formatNameToTitleCase, formatDateString,
} from "../../../utils/common-utils";
import AddCard from "../../../assets/mcg_icons/AddNewCard.svg";
import EditCard from "../../../assets/mcg_icons/EditCard.svg";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import "./secondaryCardholder-style.css";
import {
  Card,
  Cardholder,
  CardholdersData,
  IdentificationDoc,
} from "./customTypes";

export const prepareSecondaryCardholderDetails = (
  cardholdersData: CardholdersData | undefined,
  expandedCardIds: string[],
  handleViewCardsClick: (cardholder: Cardholder) => void,
  handleInviteIconClick: (cardholder: Cardholder) => void,
  handleEditCardIconClick: (cardholder: Cardholder) => void
) => {
  if (!cardholdersData) return [];
  const { linkedProfiles } = cardholdersData || {};
  return linkedProfiles.map((item: Cardholder) => {
    const { email, firstName, lastName, phoneDetails, dateOfBirth } = item;
    const fullName = formatNameToTitleCase(`${firstName} ${lastName}`);
    const phoneNumber = phoneDetails[0].phoneNumber;
    const countryDialingCode = String(phoneDetails[0].countryDialingCode);
    const phone = phoneDetails
      ? formatPhoneNumber(phoneNumber, countryDialingCode)
      : "N/A";

    const dob = dateOfBirth
      ? formatDateString(new Date(dateOfBirth).toLocaleDateString())
      : "N/A";

    const isExpanded = expandedCardIds.includes(item.cardholderId);

    return {
      email: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{email || "N/A"}</span>
          {isExpanded ? (
            <KeyboardArrowUpOutlinedIcon
              key={`up-${item.cardholderId}`}
              style={{
                color: "#047EFD",
                marginLeft: "4px",
                cursor: "pointer",
              }}
              onClick={() => handleViewCardsClick(item)}
            />
          ) : (
            <KeyboardArrowDownOutlinedIcon
              key={`up-${item.cardholderId}`}
              style={{
                color: "#047EFD",
                marginLeft: "4px",
                cursor: "pointer",
              }}
              onClick={() => handleViewCardsClick(item)}
            />
          )}
        </div>
      ),
      fullName,
      phone,
      dob,
      status: (
        <span
          style={{
            color: item.status === "EXPIRED" ? "red" : "inherit",
          }}
        >
          {item.status || "N/A"}
        </span>
      ),
      action: (
        <p>
          <img
            className="pointer underline hover-effect"
            style={{ marginLeft: "12px" }}
            onClick={() => {
              handleInviteIconClick(item);
            }}
            title="Add New Card"
            src={AddCard}
            alt="Add New Card"
          />

          <img
            className="pointer underline hover-effect"
            style={{ marginLeft: "12px" }}
            onClick={() => {
              handleEditCardIconClick(item);
            }}
            title="Edit"
            src={EditCard}
            alt="Edit"
          />
        </p>
      ),
      isExpanded,
    };
  });
};

export const prepareSecondaryCardholderCards = (
  cardholder: Cardholder | undefined,
  handleReissueCardClick: (cardId: string) => void,
  handleViewCardClick: (cardId: string) => void
) => {
  if (!cardholder) return [];
  const { cards } = cardholder || [];
  if (!Array.isArray(cards)) return [];
  return cards.map((card: Card) => {
    const { lastFour, network, status, cardId } = card;
    const cardNo = lastFour ? "****" + lastFour : "N/A";
    const expiry = card?.additionalData?.expiryDate;
    const expiryDate = expiry ? formatExpiryDate(expiry) : "N/A";
    const isExpired = [
      "CLOSED",
      "BLOCK",
      "LOST",
      "STOLEN",
      "FRAUD_BLOCKED",
    ].includes(status);

    return {
      cardNo: (
        <span
          style={{
            opacity: isExpired ? 0.5 : 1,
            cursor: isExpired ? "block" : "pointer",
            pointerEvents: isExpired ? "none" : "auto",
          }}
          onClick={!isExpired ? () => handleViewCardClick(cardId) : undefined}
        >
          {cardNo}
        </span>
      ),
      network: (
        <span
          style={{
            opacity: isExpired ? 0.5 : 1,
            pointerEvents: isExpired ? "none" : "auto",
          }}
        >
          {network || "N/A"}
        </span>
      ),
      expiryDate: (
        <span
          style={{
            opacity: isExpired ? 0.5 : 1,
            pointerEvents: isExpired ? "none" : "auto",
          }}
        >
          {expiryDate}
        </span>
      ),
      status: (
        <span
          style={{
            color: isExpired ? "red" : "inherit",
            opacity: isExpired ? 0.5 : 1,
            pointerEvents: isExpired ? "none" : "auto",
          }}
        >
          {status || "N/A"}
        </span>
      ),
      action: (
        <span
          style={{
            opacity: isExpired ? 0.5 : 1,
            cursor: isExpired ? "block" : "pointer",
            pointerEvents: isExpired ? "none" : "auto",
          }}
          onClick={
            !isExpired ? () => handleReissueCardClick(cardId) : undefined
          }
        >
          {"Reissue Card"}
        </span>
      ),
    };
  });
};

export const prepareSecondaryCardholderInfo = (cardholder: Cardholder) => {
  const {
    email,
    firstName,
    lastName,
    phoneDetails,
    dateOfBirth,
    identificationDocs,
    citizenshipCountryCode,
    addresses,
    cardholderId,
  } = cardholder;
  const countryDialingCode = phoneDetails[0].countryDialingCode || "";
  const phoneNumber = phoneDetails[0].phoneNumber;
  const maskedSSN = identificationDocs
    .filter((item: IdentificationDoc) => item.identificationType === "SSN")
    .map((item: IdentificationDoc) => item.identificationValue)[0];

  return {
    firstName,
    lastName,
    email,
    countryDialingCode,
    phoneDetails,
    phoneNumber,
    dateOfBirth,
    citizenshipCountryCode,
    maskedSSN,
    addresses,
    cardholderId,
  };
};
