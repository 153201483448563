import { Grid } from "@mui/material";
import moment from "moment";
import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { emailAlreadyExists } from "../../../../../../apis/auth_api";
import CustomButton from "../../../../../../components/custom-button";
import FlexButtons from "../../../../../../components/flex-button";
import FormField from "../../../../../../components/form/form-field";
import Loader from "../../../../../../components/shared/loader/loader";
import useConfigs from "../../../../../../hooks/use-config";
import useLabels from "../../../../../../hooks/use-labels";
import {
  dateTimeFormating,
  extractPhoneInfo,
  phoneInstance,
  showErrorNotification,
} from "../../../../../../utils/common-utils";
import { secondaryCardFields } from "../../../../../../utils/fields.utils";
import {
  errorMessage,
  fieldValidator,
} from "../../../../../../utils/validation";
import { updateCardHolder } from "../../../../../../apis/b2c_api";
import customToast from "../../../../../../components/shared/services/toaster-service";

interface SecondaryFormProps {
  onSubmit: (details: any) => void;
  cardDetails: any;
  editMode?: boolean;
  editsecondaryCardholderFlow?: boolean;
}

let hideAll = setTimeout(() => {}, 0);

const SecondaryForm: FC<SecondaryFormProps> = ({
  onSubmit,
  cardDetails,
  editMode,
  editsecondaryCardholderFlow,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    dateOfBirth,
    relation,
    countryDialingCode,
    ssn,
    phoneDetails,
    addresses,
    cardholderId,
  } = cardDetails;

  const [formData, setFormData] = useState({
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    phoneNumber: phoneNumber ? `+${countryDialingCode}${phoneNumber}` : "",
    dateOfBirth: dateOfBirth || "",
    country: "USA",
    ssn: ssn || "",
    maskedSSN: cardDetails.maskedSSN || "",
    relation: relation || "",
    phoneDetails: phoneDetails || "",
  });

  const {
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    mobileLabel,
    dobLabel,
    relationLabel,
    continueLabel,
    ssnLabel,
    countryLabel,
    backLabel,
    updateProfileSuccessMessage,
  } = useLabels([
    "firstNameLabel",
    "lastNameLabel",
    "emailLabel",
    "mobileLabel",
    "dobLabel",
    "relationLabel",
    "continueLabel",
    "ssnLabel",
    "countryLabel",
    "backLabel",
    "updateProfileSuccessMessage",
  ]);

  const { relations, nameLength } = useConfigs(["relations", "nameLength"]);

  const fieldProps = {
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    mobileLabel,
    dobLabel,
    relationLabel,
    editMode,
    relations,
    nameLength,
    ssnLabel,
    countryLabel,
  };

  const location = useLocation();

  const onSSNChange = (e: any) => {
    const errors = { ...fieldErrors };
    const maskedSSN = e.target.value;
    const hiddenPassValue = formData.ssn;

    let ssn = "";

    let showLength = 1;

    let offset = maskedSSN.length - hiddenPassValue.length;

    if (offset > 0) {
      ssn =
        hiddenPassValue +
        maskedSSN.substring(
          hiddenPassValue.length,
          hiddenPassValue.length + offset
        );
      setFormData((prev: any) => ({ ...prev, ssn }));
    } else if (offset < 0) {
      ssn = hiddenPassValue.substring(0, hiddenPassValue.length + offset);
      setFormData((prev: any) => ({ ...prev, ssn }));
    }

    setFormData((prev: any) => ({
      ...prev,
      maskedSSN:
        maskedSSN
          .substring(0, maskedSSN.length - showLength)
          .replace(/./g, "*") +
        maskedSSN.substring(maskedSSN.length - showLength, maskedSSN.length),
    }));

    if (!maskedSSN.trim()) {
      errors[e.target.name] = errorMessage.required;
    } else if (!fieldValidator.numeric(formData.ssn)) {
      errors[e.target.name] = errorMessage.ssn;
    } else {
      delete errors[e.target.name];
    }

    setFieldErrors(errors);
    clearTimeout(hideAll);
    hideAll = setTimeout(() => {
      setFormData((prev: any) => ({
        ...prev,
        maskedSSN:
          ("" + maskedSSN).slice(0, -1).replace(/./g, "*") +
          ("" + maskedSSN).slice(-1).replace(/./g, "*"),
      }));
    }, 2000);
  };

  const validate = (key: string, value: string, options?: any) => {
    let errors = { ...fieldErrors };
    const keyValidator = fieldValidator[key];

    if (typeof value === "string" && !value.trim() && options.required) {
      errors[key] = errorMessage.required;
    } else if (key === "dateOfBirth") {
      var date1 = new Date(value);
      var date2 = moment().format("MM/DD/YYYY");
      var date3 = new Date(date2);

      var Difference_In_Time = date3.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      // if (Difference_In_Days > 10957.5) {
      //   errors[key] = errorMessage.maxAgeLimit;
      // } else
      if (Difference_In_Days < 6210) {
        errors[key] = errorMessage.secMinAgeLimit;
      } else {
        delete errors[key];
      }
    } else if (
      !["phoneNumber", "ssn"].includes(key) &&
      keyValidator &&
      !keyValidator(value)
    ) {
      errors[key] = errorMessage[key];
    } else {
      delete errors[key];
    }

    setFieldErrors(errors);
  };

  const onFieldChange = (key: string, value: string, options?: any) => {
    if (key === "phoneNumber") {
      validate(key, value, options);
      value.includes("+1")
        ? setFormData((prev: any) => ({ ...prev, [key]: value }))
        : setFormData((prev: any) => ({ ...prev, [key]: "1" }));
    } else {
      validate(key, value, options);
      setFormData((prev: any) => ({ ...prev, [key]: value }));
    }
  };

  const validFields = () => {
    const errors: any = { ...fieldErrors };
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "dateOfBirth",
      "relation",
      "ssn",
      "country",
    ];

    let valid = true;
    requiredFields.forEach((field) => {
      if (!formData[field as keyof typeof formData]) {
        errors[field] = errorMessage.required;
        valid = false;
      } else if (
        ["ssn", "phoneNumber"].includes(field) &&
        !fieldValidator[field](formData[field as keyof typeof formData])
      ) {
        errors[field as keyof typeof errors] = errorMessage[field];
        valid = false;
      }
      if (formData["ssn"].length < 9) {
        errors["ssn"] = errorMessage.ssnLength;
        valid = false;
      }
    });

    setFieldErrors(errors);
    return valid;
  };

  const onContinue = () => {
    if (validFields()) {
      const phone = phoneInstance().parseAndKeepRawInput(
        formData.phoneNumber,
        ""
      );
      setLoading(true);
      emailAlreadyExists(formData.email)
        .then((exists) => {
          if (exists) {
            setLoading(false);
            return Promise.reject({ error_code: "EMAIL_ALREADY_EXISTS" });
          } else {
            onSubmit({
              ...formData,
              countryDialingCode: phone.getCountryCode(),
              phoneNumber: phone.getNationalNumber().toString(),
              phoneType: "MOBILE",
              identificationType: "SSN",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          showErrorNotification(error);
        });
    }
  };

  const onContinueEdit = () => {
    const fullPhone = formData?.phoneNumber;
    const { countryDialingCode, phoneNumber } = extractPhoneInfo(fullPhone);
    const dob =
      typeof formData?.dateOfBirth === "string"
        ? formData.dateOfBirth
        : formData?.dateOfBirth?.format("YYYY-MM-DD");
    setLoading(true);
    const editDetails = {
      dob: dob,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      address: addresses[0]?.address1,
      addressType: addresses[0]?.addressType,
      postalCode: addresses[0]?.postalCode,
      countryDialingCode: countryDialingCode,
      phoneNumber: phoneNumber,
      phoneType: phoneDetails[0]?.phoneType,
      country: addresses[0]?.country,
      region: addresses[0]?.region,
      city: addresses[0]?.city,
      email: formData?.email,
    };
    updateCardHolder(editDetails, cardholderId)
      .then(({ updateCardHolder }: any) => {
        customToast.success(updateProfileSuccessMessage);
        setLoading(false);
      })
      .catch((error: any) => {
        showErrorNotification(error);
        setLoading(false);
      });
  };

  const onBack = () => {
    if (
      location.state?.from === "SecondaryCardholderEdit" ||
      location.state?.from === "SecondaryCardholderAdd"
    ) {
      navigate("/secondary-cardholder");
    } else {
      navigate("/cards");
    }
  };

  return (
    <>
      {secondaryCardFields(fieldProps, editsecondaryCardholderFlow).map(
        (fieldItem: any, index: number) => {
          const { name, required } = fieldItem.elementProps || {};

          const fieldProps = {
            ...(fieldItem.type === "ssnMasked" && {
              onChange: (value: any) => onSSNChange(value),
              maskedSSN: formData.maskedSSN,
            }),
          };

          return (
            <Grid item key={index}>
              <FormField
                {...fieldItem}
                value={formData[name as keyof typeof formData]}
                error={fieldErrors[name]}
                onChange={(name: string, value: string) =>
                  onFieldChange(name, value, { required })
                }
                {...fieldProps}
              />
            </Grid>
          );
        }
      )}

      {loading && <Loader className="custom-loader" />}

      <FlexButtons className="layout-buttons justify-start mt-5">
        <CustomButton type="previous" text={backLabel} onClick={onBack} />
        <CustomButton
          type="primary"
          text={continueLabel}
          onClick={editsecondaryCardholderFlow ? onContinueEdit : onContinue}
          disabled={!!Object.keys(fieldErrors).length}
        />
      </FlexButtons>
    </>
  );
};

export default SecondaryForm;
