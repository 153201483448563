import useLabels from "../../../hooks/use-labels";
import Loader from "../../../components/shared/loader/loader";
import SecondaryCardholderHeader from "./SecondaryCardholderHeader";
import { useEffect, useState } from "react";
import CustomList from "../../../components/customList";
import {
  prepareSecondaryCardholderCards,
  prepareSecondaryCardholderDetails,
  prepareSecondaryCardholderInfo,
} from "./SecondaryCardholder.utils";
import { useNavigate } from "react-router";
import AddNewCardModal from "../../../components/Modal/AddNewCardModal";
import { getlinkedProfiles } from "../../../apis/b2c_api";
import { showErrorNotification } from "../../../utils/common-utils";
import { Cardholder, CardholderRow, CardholdersData } from "./customTypes";
import ReissueCardModal from "../../../components/Modal/ReissueCardModal";
import MaterialModal from "../../../components/Modal";
import CardModalContent from "../../../components/Modal/CardModalContent";

const cardholderColumns = [
  // "Sr.",
  "Email",
  "Name",
  "Phone",
  "Date of Birth",
  "Status",
  "Actions",
];

const cardsColumns = ["Card No.", "Network", "Expiry", "Status", "Action"];

export const SecondaryCardholder = () => {
  const {
    noSecondaryCardholdersText,
    secondaryCardholderLabel,
    editSecondaryCardholderLabel,
  } = useLabels([
    "noSecondaryCardholdersText",
    "secondaryCardholderLabel",
    "editSecondaryCardholderLabel",
  ]);
  const currentCardString = localStorage.getItem("currentCard");
  const card = currentCardString ? JSON.parse(currentCardString) : "";
  const { balance } = card;
  const [loading, setLoading] = useState(true);
  const [cardholdersData, setCardholdersData] = useState<CardholdersData>();
  const [cardholderRows, setCardholderRows] = useState<CardholderRow[]>([]);
  const [cardholderCards, setCardholderCards] = useState<Cardholder>();
  const [expandedCardIds, setExpandedCardIds] = useState<string[]>([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [isReissueCardModalVisible, setisReissueCardModalVisible] =
    useState(false);
  const [reissueCardId, setReissueCardId] = useState("");
  const [cardItem, setCardItem] = useState<Cardholder>();
  const [showCard, setShowCard] = useState(false);
  const [viewCardId, setViewCardId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchLinkedProfiles();
  }, []);

  useEffect(() => {
    const linkedProfiles = prepareSecondaryCardholderDetails(
      cardholdersData,
      expandedCardIds,
      handleViewCardsClick,
      handleInviteIconClick,
      handleEditCardIconClick
    );
    linkedProfiles && setCardholderRows(linkedProfiles);
  }, [cardholdersData, expandedCardIds]);

  const fetchLinkedProfiles = () => {
    setLoading(true);
    getlinkedProfiles()
      .then((data) => {
        setCardholdersData(data);
        setLoading(false);
      })
      .catch(showErrorNotification);
  };

  const handleViewCardsClick = (card: Cardholder) => {
    setExpandedCardIds((prev) => {
      if (prev.includes(card.cardholderId)) {
        return [];
      }
      return [card.cardholderId];
    });

    setCardholderCards(card);
  };

  const redirectToSecondaryCardPage = (
    from: string,
    currentPageTitle: string,
    secondaryCardholder?: {},
    secondaryCardholderId?: string,
    balance?: {},
    cardId?: string
  ) => {
    navigate("/cards/secondary-card", {
      state: {
        origin: "secondary-cardholder",
        from: from,
        secondaryCardholder,
        secondaryCardholderId,
        balance,
        cardId,
        pageCrumbs: [
          { title: "Secondary Cardholder", link: "/secondary-cardholder" },
          { title: currentPageTitle },
        ],
      },
    });
  };

  const handleEditCardIconClick = (cardholder: Cardholder) => {
    const secondaryCardholderInfo = prepareSecondaryCardholderInfo(cardholder);
    secondaryCardholderInfo &&
      redirectToSecondaryCardPage(
        "SecondaryCardholderEdit",
        editSecondaryCardholderLabel,
        secondaryCardholderInfo,
        cardholder?.cardholderId
      );
  };

  const handleInviteIconClick = (cardholder: Cardholder) => {
    setCardItem(cardholder);
    setisModalVisible(true);
  };

  const handleAddNewCardIconClick = () => {
    redirectToSecondaryCardPage(
      "SecondaryCardholderAdd",
      secondaryCardholderLabel,
      undefined,
      undefined,
      balance,
      card?.cardId
    );
  };

  const onReissueCard = (cardId: string) => {
    setisReissueCardModalVisible(true);
    setReissueCardId(cardId);
  };

  const handleReissueCardClick = (cardId: string) => {
    onReissueCard(cardId);
  };

  const onModalClose = () => {
    setisModalVisible(false);
  };

  const onReissueModalClose = () => {
    setisReissueCardModalVisible(false);
  };

  const handleViewCardClick = (cardId: string) => {
    setShowCard(true)
    cardId && setViewCardId(cardId);
  };

  const secondaryCards = prepareSecondaryCardholderCards(
    cardholderCards,
    handleReissueCardClick,
    handleViewCardClick
  );

  const renderActionModal = () => {
    return (
      <AddNewCardModal
        isOpen={isModalVisible}
        onClose={onModalClose}
        cardItem={cardItem}
      />
    );
  };

  const renderReissueModal = () => {
    return (
      <ReissueCardModal
        isOpen={isReissueCardModalVisible}
        onClose={onReissueModalClose}
        cardId={reissueCardId}
      />
    );
  };

  return (
    <>
      {renderReissueModal()}
      {renderActionModal()}

      {showCard ? (
        <MaterialModal
          open={showCard}
          handleClose={() => setShowCard(false)}
          width={"370px"}
          boxClass={"card-widget-box"}
        >
          <CardModalContent
            cardId={viewCardId}
            onClose={() => setShowCard(false)}
          />
        </MaterialModal>
      ) : null}

      <SecondaryCardholderHeader
        balance={balance}
        handleAddNewCardIconClick={handleAddNewCardIconClick}
      />
      {loading ? (
        <Loader className="custom-loader" />
      ) : (
        <CustomList
          rows={cardholderRows}
          columns={cardholderColumns}
          cardsColumns={cardsColumns}
          secondaryCards={secondaryCards}
          noDataText={noSecondaryCardholdersText}
          onRowClick={handleViewCardsClick}
        />
      )}
    </>
  );
};

export default SecondaryCardholder;
