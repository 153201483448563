import MaterialModal from "..";
import { Cardholder } from "../../../views/dashboard/SecondaryCardholder/customTypes";
import ReissueCardModalContent from "./ReissueCardModalContent";


interface ReissueCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  cardId: string | undefined;
}

const ReissueCardModal = ({ isOpen, ...rest }: ReissueCardModalProps) => {
  return (
    <MaterialModal open={isOpen} handleClose={rest.onClose} width={"25rem"}>
      <ReissueCardModalContent {...rest} />
    </MaterialModal>
  );
};

export default ReissueCardModal;
