import { RootStateOrAny, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router";
import SignupInvitations from "../components/Modal/SIgnupInvitationModal/SignupInvitationModalContent/SignupInvitations";
import useConfigs from "../hooks/use-config";
import Dashboard from "./dashboard";
import Cards from "./dashboard/Content/Cards";
import TransactionDetail from "./dashboard/Content/Cards/CardContent/TransactionHistory/TransactionDetail";
import SecondaryCard from "./dashboard/Content/Cards/SecondaryCard";
import UserProfile from "./dashboard/UserProfile";
import Recipients from './dashboard/Recipients'
import TransferHistory from "./dashboard/TransferHistory/transferHistory";
import SecondaryCardholder from "./dashboard/SecondaryCardholder";

const AuthWrapper = () => {
  const { activeUser } = useSelector((state: RootStateOrAny) => state.users);
  const { defaultMainView, showInvitationMenu } = useConfigs([
    "defaultMainView",
    "showInvitationMenu",
  ]);
  const location = useLocation();

  if (activeUser.access_token) {
    return (
      <>
        <Dashboard>
          <Routes>
            <Route path="/update-profile" element={<UserProfile />} />
            {showInvitationMenu && (
              <Route path="/invitations" element={<SignupInvitations cardId={activeUser.cardId} />} />
            )}
            <Route path="/transfer-history" element={<TransferHistory />} />
            <Route path="/secondary-cardholder" element={<SecondaryCardholder />} />
            <Route path="/transfer-funds" element={<Recipients />} />
            <Route path="/cards" element={<Cards />} />
            <Route
              path="/cards/transaction-detail"
              element={<TransactionDetail />}
            />

            <Route path="/cards/secondary-card" element={<SecondaryCard />} />

            <Route path="*" element={<Navigate to="/cards" replace />} />
          </Routes>
        </Dashboard>
      </>
    );
  }

  return (
    <Navigate to={`/${defaultMainView}`} replace state={{ from: location }} />
  );
};

export default AuthWrapper;
