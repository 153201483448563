import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useStyles from "../../hooks/use-styles";
import { FC } from "react";
import { CardsRow } from "../../views/dashboard/SecondaryCardholder/customTypes";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#585858",
    padding: "7px",
    fontSize: ".8rem",
    backgroundColor: "#F0F7FF",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: ".8rem",
    color: "#34495E",
    backgroundColor: "#F5F5F5",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "th:first-of-type, td:first-of-type": {
    paddingLeft: 50,
  },
  "td:first-of-type": {
    color: "#047EFD",
  },
  "td:last-of-type": {
    color: "#047EFD",
    width: "35%",
    textDecoration: "underline",
  },
}));

interface CustomSecondaryCardsListProps {
  columns: Array<string>;
  rows: CardsRow[];
  noDataText?: string;
  scrollableHeight?: number;
  noDataStyle?: any;
}

const CustomSecondaryCardsList: FC<CustomSecondaryCardsListProps> = ({
  columns,
  rows,
  noDataText,
  scrollableHeight,
  noDataStyle,
}) => {
  const { fontColor } = useStyles(["fontColor"]);
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "0",
        boxShadow: "unset",
        backgroundColor: "transparent",
        height: scrollableHeight || "100%",
      }}
    >
      <Table stickyHeader aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            {columns.map((column: string) => (
              <StyledTableCell key={column} align="left" className="weight-600">
                {column}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((row: CardsRow, rowIndex: number) => (
              <StyledTableRow key={`row ${rowIndex}`}>
                {Object.keys(row).map((key: string, colIndex: number) => (
                  <StyledTableCell
                    key={`${key}-${colIndex}-${rowIndex}`}
                    align="left"
                  >
                    {row[key as keyof CardsRow]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <p
                  className="table-no-data-text subTitle"
                  style={{ color: fontColor, ...noDataStyle }}
                >
                  {noDataText}
                </p>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomSecondaryCardsList;
